import { cn } from "@/lib/utils";
import { inject, observer } from "mobx-react";
import ExportedImage from "next-image-export-optimizer";
import { useEffect, useState } from "react";
import store from "stores/store";
import { getAltTextFromGeneralImage, getImageUrlFromGeneralImage } from "utils/imageHelpers";

const HeroComingSoon = () => {
    if (!store) {
        throw Error("Component was not setup correctly");
    }

    const { startLocalized, comingSoonLocalized } = store;

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getImageSize = () => {
        if (windowWidth < 768) {
            return comingSoonLocalized?.heroMobile;
        } else if (windowWidth >= 768) {
            return comingSoonLocalized?.heroDesktop;
        }
    };

    const hero = getImageSize();

    return (
        <div className={cn("relative w-full h-full flex items-center justify-center")}>
            {hero !== undefined && hero !== null && (
                <div className={cn("absolute h-full w-full")}>
                    <ExportedImage
                        src={getImageUrlFromGeneralImage(hero)}
                        alt={getAltTextFromGeneralImage(hero)}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
            )}
            <div className="absolute w-full h-full max-w-[441px] flex flex-col items-center justify-center">
                {comingSoonLocalized?.claim !== undefined && comingSoonLocalized.claim !== null && (
                    <div className={cn("relative h-[202px] w-[318px]")}>
                        <ExportedImage
                            src={getImageUrlFromGeneralImage(comingSoonLocalized?.claim)}
                            alt={getAltTextFromGeneralImage(comingSoonLocalized?.claim)}
                            layout="fill"
                        />
                    </div>
                )}
                <div
                    className={cn(
                        "bg-whiteShade-10 w-[289px] h-[140px] rounded-[16px] relative top-[-15px]",
                        "2xs:w-[416px] 2xs:h-[118px]",
                        "md:w-[385px] md:h-[118px]",
                    )}
                >
                    <div
                        className={cn(
                            "bg-petrolShade-85 w-[289px] h-[140px] rounded-[16px] px-[36px] py-[24px] flex flex-col items-center text-center gap-[2px] justify-center",
                            "2xs:w-[416px] 2xs:h-[118px]",
                            "md:w-[385px] md:h-[118px]",
                        )}
                    >
                        <div className="font-frutigerBlackItalic italic font-black text-[20px] leading-[135%] text-shadow-coming-soon-card-text-drop">
                            {comingSoonLocalized?.cardTitle}
                        </div>
                        <div className="font-frutigerBold font-bold leading-[135%] tracking-minus-2 text-[16px] text-shadow-coming-soon-card-text-drop">
                            {comingSoonLocalized?.cardSubtitle}
                        </div>
                        <div className="font-frutigerBold font-bold leading-[135%] tracking-minus-2 text-[16px] text-shadow-coming-soon-card-text-drop underline">
                            {comingSoonLocalized?.cardLink}
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        "font-frutigerBold font-bold text-[12px] text-center w-[250px] relative leading-[100%] mt-[9px]",
                        "2xs:w-full",
                    )}
                >
                    {comingSoonLocalized?.link}
                </div>
            </div>
        </div>
    );
};

export default inject("store")(observer(HeroComingSoon));
